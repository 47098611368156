import state from './state';

export default {
	getFiltersConstructorMZ: () => state.filtersConstructorMZ,
	getParams: () => state.params,
	loadingTable: () => state.filtersConstructorMZ.expertMode.loadingTable,
	getDataTable: () => state.filtersConstructorMZ.expertMode.dataTable,
	getRegions: () => state.regions,
	getDisabledBtnAnd: () => state.filtersConstructorMZ.expertMode.disabledAnd,
	getDisabledBtnOr: () => state.filtersConstructorMZ.expertMode.disabledOr,
	getNomenclatureList: () => state.filtersConstructorMZ.userMode.nomenclature,
	getNoteList: () => state.filtersConstructorMZ.userMode.note,
	getOwnershipList: () => state.filtersConstructorMZ.userMode.ownership,
	getDepartmentBelongList: () => state.filtersConstructorMZ.userMode.departmentBelong,
	getYears: () => state.years,
	getAmbulatory: () => state.filtersConstructorMZ.userMode.ambulatory,
	getSanatory: () => state.filtersConstructorMZ.userMode.sanatory,
	getPsychiatric: () => state.filtersConstructorMZ.userMode.psychiatric,
	getStationary: () => state.filtersConstructorMZ.userMode.stationary,
	getHeadOffice: () => state.filtersConstructorMZ.userMode.headOffice,
	getBranchOffice: () => state.filtersConstructorMZ.userMode.branchOffice,
	getFlagDownloadExcel: () => state.filtersConstructorMZ.flagDownloadExcel,
	getIdForExcel: () => state.filtersConstructorMZ.idForExcel
};
