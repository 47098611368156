<template>
	<div class="radio-group">
		<p class="block font-bold text-sm text-textGray" :class="[mb]">
			{{ title }}
		</p>
		<div class="flex gap-5">
			<label v-for="option in options" :key="option.id" :for="option.id" class="block text-sm text-gray-500 font-normal" :class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }">
				<input
					:class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }"
					type="radio"
					:name="name"
					:id="option.id"
					:disabled="disabled"
					:value="value"
					:checked="option.checked"
					@change="$emit('input', option.title)"
				/>
				{{ option.title }}
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RadioGroup',
	props: {
		title: String,
		options: Array,
		name: String,
		disabled: Boolean,
		value: [Number, String, Boolean],
		mb: {
			type: String,
			default: () => 'mb-3'
		}
	}
};
</script>

<style scoped>
input[type='radio'] {
	appearance: none;
	background-color: #fff;
	width: 20px;
	height: 20px;
	border: 2px solid #a8b1ce;
	border-radius: 2px;
	display: inline-grid;
	place-content: center;
	margin-right: 6px;
}

input[type='radio']::before {
	content: '';
	width: 10px;
	height: 10px;
	transform: scale(0);
	transform-origin: bottom left;
	background-color: #fff;
	clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type='radio']:checked::before {
	transform: scale(1);
}
input[type='radio']:checked {
	background-color: #3377ff;
	border: 2px solid #3377ff;
}
input[type='radio']:checked:disabled {
	background-color: #bec7da;
	border: 2px solid #bec7da;
}
</style>
