<template>
	<div class="w-full">
		<ul class="block p-0 list-none">
			<div class="border-b-2 border-dopBlue w-max">
				<li
					v-for="(tab, index) in tabs"
					:key="tab.title"
					@click="selectTab(index)"
					class="h-14 py-3.5 px-7 m-0 inline-block mr-2.5 cursor-pointer rounded-t-lg border-t border-r border-l text-sm leading-4 font-bold tracking-wider last:mr-0"
					:class="index === selectedIndex ? 'text-white bg-dopBlue border-dopBlue' : 'text-gray-400 bg-transparent border-gray-400'"
				>
					<div class="flex items-center justify-center h-full">
						{{ tab.title }}
					</div>
				</li>
			</div>
		</ul>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'Tabs',
	components: {},
	data() {
		return {
			selectedIndex: 0,
			tabs: []
		};
	},
	created() {
		this.tabs = this.$children;
	},
	mounted() {
		this.selectTab(0);

		this.$root.$on('eventsUpdate', (index) => {
			this.selectTab(index);
		});
	},
	methods: {
		selectTab(i) {
			this.selectedIndex = i;
			this.tabs.forEach((tab, index) => {
				tab.isActive = index === i;
			});
			this.$emit('click', i);
		}
	}
};
</script>
