<template>
	<div>
		<div class="flex flex-col relative">
			<div class="t-overflow rounded-tr-lg overflow-x-auto h-auto bg-white filter shadow-default">
				<table class="table-fixed w-full" :style="data.length > 0 ? 'min-height: 420px' : ''">
					<thead>
						<tr>
							<th
								v-for="(item, ind) in fields"
								:key="'J' + ind"
								class="px-6 py-4 border-b border-r border-gray-200 text-sm leading-4 font-semibold text-gray-500 tracking-wider"
								:class="{ 'text-left cell-first w-6/12': ind === 0, 'w-4/12 text-center cell-second': ind === 1 }"
							>
								<span class="flex relative">
									<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />

									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="ind === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:item="item"
									/>
								</span>
							</th>
							<th class="w-2/12 px-6 py-4 border-b border-r border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
								<span class="relative">
									<span> Действие </span>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, indx) in data" :key="'A' + indx">
							<td class="w-6/12 px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
								<span>
									{{ item.name }}
								</span>
							</td>
							<td class="w-4/12 px-6 py-4 font-normal text-sm text-center whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								<span class="bg-textGray text-center text-xs text-white px-2 py-1 rounded">
									{{ item.type === 2 ? 'Экспертный' : 'Пользовательский' }}
								</span>
							</td>
							<td class="w-2/12 px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								<span class="flex">
									<Icons class="mr-2 icon-control" icon-name="diagramGray" @click.native="openReport(item)" />
									<Icons class="mr-2 icon-control" icon-name="upload" @click.native="!disabledUpload ? uploadReport(item.id) : ''" />
									<Icons v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="icon-control" icon-name="trash" @click.native="openDelPopup(item, indx)" />
								</span>
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<Pagination :rowsCount="totalRows" :pageSize="params.pageSize" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
			</div>
			<!-- Прелоудер -->
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<PopUp :open="openDelete" size="441px" title="Удалить отчет" @close="closeDelPopup()">
			<div>
				<div class="text-sm font-bold text-textGray mb-3 leading-5">Вы действительно хотите удалить сохраненный отчет?</div>
				<div class="text-base font-bold leading-6">{{ deletedItem.name }}</div>
				<!-- - {{ deletedItem.mode }} режим -->
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closeDelPopup()" />
				<Button size="medium" text="Удалить" @click.native="delReport(deletedItem.id)" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import { getters, methods } from '@/store/store';
import FilterComponent from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';
import Pagination from '@/components/Pagination.vue';
import loadingComponent from '@/components/Loading.vue';
import PopUp from '@/components/PopUp.vue';
import Button from '@/components/Button.vue';
import { getExpertReportSettings, deleteReport, getResultReportByIdExcel, getMedicalOrgs } from '../store/actions';
import mutations from '../store/mutations';

export default {
	name: 'SavedReports',
	components: {
		loadingComponent,
		Icons,
		FilterComponent,
		Button,
		PopUp,
		Pagination
	},
	computed: {
		...getters
	},
	data() {
		return {
			index: null,
			loadingTable: false,
			disabledUpload: false,
			openDelete: false,
			filterOpen: null,
			filterBy: null,
			totalRows: 0,
			totalCount: 0,
			page: 0,
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: this.$DefaultPageSize,
				currentPage: 0
			},
			deletedItem: {
				id: '',
				region: '',
				mode: ''
			},
			fields: [
				{
					Name: 'Название',
					fieldName: 'name',
					filterType: 2,
					type: '',
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Режим',
					fieldName: 'type',
					filterType: 1,
					type: 'radiobutton',
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			data: []
		};
	},
	methods: {
		...methods,
		defaultParams: mutations.defaultParams,
		reCalculateParams: mutations.reCalculateParams,
		changeDataTable: mutations.changeDataTable,
		loadTable: mutations.loadTable,
		changeDisabledBtn: mutations.changeDisabledBtn,
		changeFlagDownloadExcel: mutations.changeFlagDownloadExcel,
		changeIdForExcel: mutations.changeIdForExcel,
		updatePage(input) {
			this.params.pageSize = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 12;
			}

			getExpertReportSettings(this.$http, params).then((res) => {
				this.data = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = params.pageSize ? Math.ceil(res.data.totalRecords / params.pageSize) : 1;
				this.loadingTable = false;
			});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		openDelPopup(item, index) {
			this.index = index;
			this.openDelete = true;
			this.deletedItem = item;
		},
		closeDelPopup() {
			this.index = null;
			this.openDelete = false;
		},
		delReport(id) {
			deleteReport(this.$http, id)
				.then((res) => {
					if (res.data) {
						if (this.index > -1) this.data.splice(this.index, 1);
						this.showNotification('Сохраненный отчет удален успешно!', 'success');
						this.closeDelPopup();
					}
				})
				.catch((error) => {
					if (error) {
						this.showNotification('Ошибка при удалении отчета, обратитесь к Администратору!', 'error');
					}
				});
		},
		uploadReport(id) {
			this.disabledUpload = true;

			getResultReportByIdExcel(this.$http, id)
				.then((res) => {
					let fileNameAndType = decodeURI(res.headers['content-disposition'].split('filename*=')[1].split("''")[1]);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.disabledUpload = false;
				})
				.catch((error) => {
					if (error) {
						this.showNotification('Ошибка при выгрузке отчета, обратитесь к Администратору!', 'error');
					}
				});
		},
		openReport(item) {
			// Если режим эксперта
			if (item.type === 2) {
				if (item.data.useANDoperator) {
					this.changeDisabledBtn('disabledAnd', false);
					this.changeDisabledBtn('disabledOr', true);
				} else {
					this.changeDisabledBtn('disabledAnd', true);
					this.changeDisabledBtn('disabledOr', false);
				}
			}

			this.defaultParams();
			this.reCalculateParams(item.filtersDefault, item.type, item.data);

			getMedicalOrgs(this.$http, item.data).then((res) => {
				this.changeDataTable(res.data);
				this.$root.$emit('eventsUpdate', item.type === 1 ? 0 : 1);
				this.changeFlagDownloadExcel(false);
				this.changeIdForExcel(item.id);
			});
		},
		getAllReportSettings() {
			getExpertReportSettings(this.$http, {
				filters: this.params.filters,
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 999999,
				currentPage: 0
			}).then((res) => {
				this.fields[0].list = res.data.data;
			});
		}
	},
	created() {
		this.getData(this.params, false);
		this.getAllReportSettings();
	}
};
</script>

<style>
.cell-first {
	width: 640px;
}

.cell-second {
	width: 213px;
}

.icon-control {
	cursor: pointer;
}

.icon-control:hover rect {
	fill: #3377ff;
}
</style>
