import state from './state';

export default {
	updateExpertInputs(name, obj, index) {
		if (name === 'add') state.filtersConstructorMZ.expertMode.expertInputs.push(obj);
		if (name === 'del') state.filtersConstructorMZ.expertMode.expertInputs.splice(index, 1);
		if (name === 'reset') state.filtersConstructorMZ.expertMode.expertInputs = [];
	},
	loadTable(val) {
		state.filtersConstructorMZ.expertMode.loadingTable = val;
	},
	changeDataTable(obj) {
		state.filtersConstructorMZ.expertMode.dataTable.data = obj.data;
		state.filtersConstructorMZ.expertMode.dataTable.totalRows = obj.totalRecords;
		state.filtersConstructorMZ.expertMode.dataTable.page = obj.currentPage;
		state.filtersConstructorMZ.expertMode.dataTable.totalCount = obj.pageSize ? Math.ceil(obj.totalRecords / obj.pageSize) : 1;
	},
	setYears(array) {
		state.years = array;
	},
	setRegions(array) {
		state.regions = array;
	},
	defaultParams() {
		state.params = {
			filters: [],
			sortOption: {
				fieldName: '',
				sortType: null
			},
			pageSize: 100,
			currentPage: 0,
			useANDoperator: true,
			columns: []
		};
	},
	calculateParams(array) {
		for (let i = 0; i < array.length; i += 1) {
			if (i === 0) state.params.useANDoperator = array[i].buttonType === 'and' ? true : false;

			// Условия оказания = terms
			if (array[i]?.field?.fieldName === 'terms') {
				if (array[i].value.length > 0) {
					for (let j = 0; j < array[i].value.length; j += 1) {
						state.params.filters.push({
							fieldName: state.setMapConditionsOfRendering[array[i].value[j].name],
							filterType: 1,
							filterValueType: 2,
							value1: 'true',
							value2: ''
						});
					}
				}
			} else {
				let fieldName = array[i]?.field?.fieldName,
					filterType = array[i]?.type?.fieldName,
					filterValueType = array[i]?.field?.type,
					value = !array[i]?.value ? filterType : array[i]?.value,
					selectEqualOnlyFlag = filterValueType === 'selectEqualOnly',
					selectEqualOnlySingleFlag = filterValueType === 'selectEqualOnlySingle',
					valueNested = selectEqualOnlySingleFlag ? value.id : value;
				if (fieldName) {
					state.params.filters.push({
						fieldName: fieldName,
						filterType: selectEqualOnlyFlag ? 9 : state.setMapFilType[filterType],
						filterValueType: state.setMapFilValType[filterValueType],
						value1: selectEqualOnlyFlag ? '' : valueNested,
						value2: '',
						values: selectEqualOnlyFlag ? value.map((m) => m.id || m.name) : undefined
					});
				}
			}
		}
	},
	reCalculateParams(filtersDefault, type, params) {
		state.params = params;
		if (type === 2) state.filtersConstructorMZ.expertMode.expertInputs = JSON.parse(filtersDefault);
		if (type === 1) state.filtersConstructorMZ.userMode.filters = JSON.parse(filtersDefault);
	},
	changeDisabledBtn(name, val) {
		state.filtersConstructorMZ.expertMode[name] = val;
	},
	addGuide(name, val) {
		let result = [];

		if (val.length > 0) {
			for (let i = 0; i < val.length; i += 1) {
				result.push({
					id: val[i].id,
					name: val[i].lookupName,
					checked: false
				});
			}
		}

		state.filtersConstructorMZ.userMode[name] = result;
	},
	calculateUserMode(obj) {
		state.params.useANDoperator = true;

		for (const key in obj) {
			if (Object.hasOwnProperty.call(obj, key)) {
				if (obj[key] && obj[key].length > 0) {
					// Если флаги
					if (obj[key] === 'Да' || obj[key] === 'Нет') {
						state.params.filters.push({
							fieldName: key,
							filterType: 1,
							filterValueType: 2,
							value1: obj[key] === 'Да' ? 'true' : 'false',
							value2: ''
						});
					}

					// Если текст
					if (obj[key] !== 'Да' && obj[key] !== 'Нет' && typeof obj[key] === 'string') {
						state.params.filters.push({
							fieldName: key,
							filterType: 2,
							filterValueType: 1,
							value1: obj[key],
							value2: ''
						});
					}

					// Если массив
					if (Array.isArray(obj[key])) {
						state.params.filters.push({
							fieldName: key,
							filterType: 9,
							filterValueType: 1,
							value1: '',
							value2: '',
							values: key === 'YearStr' ? obj[key].map((m) => m.name) : obj[key].map((m) => m.id)
						});
					}
				}
			}
		}
	},
	changeFlagDownloadExcel(val) {
		state.filtersConstructorMZ.flagDownloadExcel = val;
	},
	changeIdForExcel(id) {
		state.filtersConstructorMZ.idForExcel = id;
	},
	resetFiltersForUserMode(obj) {
		let array = ['ambulatory', 'sanatory', 'psychiatric', 'stationary', 'headOffice', 'branchOffice'];

		state.filtersConstructorMZ.userMode.filters = { ...obj };
		for (let i = 0; i < array.length; i += 1) {
			for (let j = 0; j < state.filtersConstructorMZ.userMode[array[i]].length; j += 1) {
				state.filtersConstructorMZ.userMode[array[i]][j].checked = false;
			}
		}

		let rootOptions = ['years', 'regions'];

		for (let i = 0; i < rootOptions.length; i += 1) {
			state[rootOptions[i]].forEach((x) => (x.checked = false));
		}

		let guideOptions = ['nomenclature', 'departmentBelong', 'ownership', 'note'];

		for (let i = 0; i < guideOptions.length; i += 1) {
			state.filtersConstructorMZ.userMode[guideOptions[i]].forEach((x) => (x.checked = false));
		}
	}
};
