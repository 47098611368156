<template>
	<div class="rounded-r-lg rounded-b-lg h-auto bg-white filter shadow-default">
		<div class="flex flex-col p-7">
			<h3 class="w-full text-lg text-black font-extrabold leading-5 tracking-wide mb-5">Фильтр параметров отчета</h3>
			<div class="w-full flex items-end gap-5 mb-7">
				<div class="w-2/3 user-mode__input">
					<TextInput v-model="getFiltersConstructorMZ.userMode.filters.Name" label="Полное наименование МО" />
				</div>
				<div class="w-1/3 user-mode__input">
					<TextInput v-model="getFiltersConstructorMZ.userMode.filters.ShortName" label="Краткое наименование" />
				</div>
			</div>
			<div class="w-full flex items-end gap-5 mb-7">
				<div class="w-1/5 user-mode__input">
					<TextInput v-model="getFiltersConstructorMZ.userMode.filters.Inn" type="number" label="ИНН МО" />
				</div>
				<div class="w-1/5 user-mode__select">
					<label class="block mb-3 text-textGray font-bold text-sm leading-5">Номенклатура МО</label>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="getFiltersConstructorMZ.userMode.filters.NomenclatureId"
						:options="getNomenclatureList"
						:searchable="false"
						:multiple="true"
						:limit="1"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="onSelect($event, getNomenclatureList)"
						@remove="onRemove($event, getNomenclatureList)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
						<template slot="limit">
							<span></span>
						</template>
					</multiselect>
				</div>
				<div class="w-1/5 user-mode__select">
					<label class="block mb-3 text-textGray font-bold text-sm leading-5">Вкл. в перечень определенного года</label>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="getFiltersConstructorMZ.userMode.filters.YearStr"
						:options="getYears"
						:searchable="false"
						:multiple="true"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="onSelect($event, getYears)"
						@remove="onRemove($event, getYears)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
					</multiselect>
				</div>
				<div class="w-1/5 user-mode__select">
					<label class="block mb-3 text-textGray font-bold text-sm leading-5">Субъект</label>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="getFiltersConstructorMZ.userMode.filters.RegionId"
						:options="getRegions"
						:searchable="false"
						:multiple="true"
						:limit="1"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="onSelect($event, getRegions)"
						@remove="onRemove($event, getRegions)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
						<template slot="limit">
							<span></span>
						</template>
					</multiselect>
				</div>
				<div class="w-1/5 user-mode__select">
					<label class="block mb-3 text-textGray font-bold text-sm leading-5">Ведомственная принадлежность</label>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="getFiltersConstructorMZ.userMode.filters.DepartmentBelongId"
						:options="getDepartmentBelongList"
						:searchable="false"
						:multiple="true"
						:limit="1"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="onSelect($event, getDepartmentBelongList)"
						@remove="onRemove($event, getDepartmentBelongList)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
						<template slot="limit">
							<span></span>
						</template>
					</multiselect>
				</div>
			</div>
			<div class="w-full flex items-end gap-5 mb-7">
				<div class="w-1/3 user-mode__select">
					<label class="block mb-3 text-textGray font-bold text-sm leading-5">Форма собственности</label>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="getFiltersConstructorMZ.userMode.filters.OwnerShipId"
						:options="getOwnershipList"
						:searchable="false"
						:multiple="true"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="onSelect($event, getOwnershipList)"
						@remove="onRemove($event, getOwnershipList)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
					</multiselect>
				</div>
				<div class="w-1/3 user-mode__select">
					<label class="block mb-3 text-textGray font-bold text-sm leading-5">Примечание</label>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="getFiltersConstructorMZ.userMode.filters.NoteId"
						:options="getNoteList"
						:searchable="false"
						:multiple="true"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="onSelect($event, getNoteList)"
						@remove="onRemove($event, getNoteList)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
					</multiselect>
				</div>
				<div class="w-1/3 user-mode__input">
					<TextInput v-model="getFiltersConstructorMZ.userMode.filters.WebSiteAddress" label="Адрес официального сайта" />
				</div>
			</div>
			<div class="w-full flex items-end gap-5 mb-7">
				<div class="w-1/2">
					<TextInput v-model="getFiltersConstructorMZ.userMode.filters.Address" label="Фактический адрес" />
				</div>
				<div class="w-1/2">
					<TextInput v-model="getFiltersConstructorMZ.userMode.filters.PostAddress" label="Почтовый адрес" />
				</div>
			</div>
			<div class="w-full flex items-end gap-5 mb-10">
				<div class="w-1/6">
					<RadioGroup
						v-model="getFiltersConstructorMZ.userMode.filters.Ambulator"
						title="Амбулаторные"
						name="ambulator"
						:options="getAmbulatory"
						@input="onRadioInput(getFiltersConstructorMZ.userMode.filters.Ambulator, getAmbulatory)"
					/>
				</div>
				<div class="w-1/6">
					<RadioGroup
						v-model="getFiltersConstructorMZ.userMode.filters.HealthResort"
						title="Санаторные"
						name="healthResort"
						:options="getSanatory"
						@input="onRadioInput(getFiltersConstructorMZ.userMode.filters.HealthResort, getSanatory)"
					/>
				</div>
				<div class="w-1/6">
					<RadioGroup
						v-model="getFiltersConstructorMZ.userMode.filters.Psychiatric"
						title="Психиатрические"
						name="psychiatric"
						:options="getPsychiatric"
						@input="onRadioInput(getFiltersConstructorMZ.userMode.filters.Psychiatric, getPsychiatric)"
					/>
				</div>
				<div class="w-1/6">
					<RadioGroup
						v-model="getFiltersConstructorMZ.userMode.filters.Stacionar"
						title="Стационарные"
						name="stacionar"
						:options="getStationary"
						@input="onRadioInput(getFiltersConstructorMZ.userMode.filters.Stacionar, getStationary)"
					/>
				</div>
				<div class="w-1/6">
					<RadioGroup
						v-model="getFiltersConstructorMZ.userMode.filters.isMain"
						title="Головное учреждение"
						name="headOrganisation"
						:options="getHeadOffice"
						@input="onRadioInput(getFiltersConstructorMZ.userMode.filters.isMain, getHeadOffice)"
					/>
				</div>
				<div class="w-1/6">
					<RadioGroup
						v-model="getFiltersConstructorMZ.userMode.filters.isBranch"
						title="Филиал"
						name="filial"
						:options="getBranchOffice"
						@input="onRadioInput(getFiltersConstructorMZ.userMode.filters.isBranch, getBranchOffice)"
					/>
				</div>
			</div>
			<div class="w-full flex items-end gap-5">
				<div class="w-36">
					<Button text="Сбросить" size="full" @click="resetInput" />
				</div>
				<div class="w-1/4">
					<Button text="Построить отчет" @click="getReport(getFiltersConstructorMZ.userMode.filters)" size="full" />
				</div>
				<div class="w-3/4">
					<TextInputButton
						placeholder="Введите название отчета и нажмите сохранить, если хотите воспользоваться отчетом в будущем"
						buttonTitle="Сохранить"
						:disabled="buttonDisabled"
						v-model="nameSaved"
						@input="errorSaved = false"
						:error="errorSaved"
						@click="save($http, getFiltersConstructorMZ.userMode.filters, nameSaved)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import Multiselect from 'vue-multiselect';
import RadioGroup from '@/components/RadioGroup.vue';
import Button from '@/components/Button.vue';
import TextInputButton from '@/components/TextInputButton.vue';
import { getGuide, getMedicalOrgs, setReports } from '../store/actions';
import getters from '../store/getters';
import mutations from '../store/mutations';

export default {
	name: 'UserMode',
	components: {
		TextInput,
		Multiselect,
		RadioGroup,
		Button,
		TextInputButton
	},
	computed: {
		getFiltersConstructorMZ: getters.getFiltersConstructorMZ,
		getNomenclatureList: getters.getNomenclatureList,
		getNoteList: getters.getNoteList,
		getOwnershipList: getters.getOwnershipList,
		getDepartmentBelongList: getters.getDepartmentBelongList,
		getRegions: getters.getRegions,
		getYears: getters.getYears,
		getAmbulatory: getters.getAmbulatory,
		getSanatory: getters.getSanatory,
		getPsychiatric: getters.getPsychiatric,
		getStationary: getters.getStationary,
		getHeadOffice: getters.getHeadOffice,
		getBranchOffice: getters.getBranchOffice,
		getParams: getters.getParams
	},
	data() {
		return {
			nameSaved: '',
			errorSaved: false,
			buttonDisabled: false
		};
	},
	methods: {
		calculateUserMode: mutations.calculateUserMode,
		defaultParams: mutations.defaultParams,
		loadTable: mutations.loadTable,
		changeDataTable: mutations.changeDataTable,
		changeIdForExcel: mutations.changeIdForExcel,
		changeFlagDownloadExcel: mutations.changeFlagDownloadExcel,
		resetFiltersForUserMode: mutations.resetFiltersForUserMode,
		onSelect(option, options) {
			let index = options.findIndex((item) => item.name === option.name);
			options[index].checked = true;
		},
		onRemove(option, options) {
			let index = options.findIndex((item) => item.name === option.name);
			options[index].checked = false;
		},
		onRadioInput(option, options) {
			options.forEach((x) => {
				x.checked = x.title === option;
			});
		},
		getReport(object) {
			this.defaultParams();
			this.calculateUserMode(object);
			this.loadTable(true);

			getMedicalOrgs(this.$http, this.getParams).then((res) => {
				this.changeDataTable(res.data);
				this.changeFlagDownloadExcel(true);
				this.loadTable(false);
			});
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		save(http, object, name) {
			if (name.length === 0) {
				this.showNotification('Заполните название отчета!', 'error');
				this.errorSaved = true;
				return;
			}
			this.buttonDisabled = true;

			this.defaultParams();
			this.calculateUserMode(object);

			setReports(http, {
				name: name,
				data: this.getParams,
				filtersDefault: JSON.stringify(object),
				type: 1
			})
				.then((result) => {
					if (result.status === 200) {
						this.changeIdForExcel(result.data.id);
						this.changeFlagDownloadExcel(false);
						this.showNotification(`Сохранение отчета - ${this.nameSaved} прошло успешно!`, 'success');
						this.buttonDisabled = false;
						// this.nameSaved = '';
					}
				})
				.catch((error) => {
					if (error) {
						this.showNotification('Ошибка сохранения, обратитесь к Администратору!', 'error');
						this.buttonDisabled = false;
					}
				});
		},
		resetInput() {
			this.resetFiltersForUserMode({
				Name: '',
				ShortName: '',
				Inn: '',
				NomenclatureId: [],
				YearStr: [],
				RegionId: [],
				DepartmentBelongId: [],
				OwnerShipId: [],
				NoteId: [],
				WebSiteAddress: '',
				Address: '',
				PostAddress: '',
				Ambulator: null,
				HealthResort: null,
				Psychiatric: null,
				Stacionar: null,
				isMain: null,
				isBranch: null
			});
		}
	},
	created() {
		if (this.getNomenclatureList.length === 0) {
			getGuide(this.$http, 'nomenclature', true);
		}

		if (this.getDepartmentBelongList.length === 0) {
			getGuide(this.$http, 'departmentBelong', true);
		}

		if (this.getOwnershipList.length === 0) {
			getGuide(this.$http, 'ownership', true);
		}

		if (this.getNoteList.length === 0) {
			getGuide(this.$http, 'note', true);
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.user-mode__input > .input-box {
	margin-bottom: 0;
}

.user-mode__input > .input-box input[type='number'] {
	-moz-appearance: textfield;
}

.user-mode__select > .multiselect {
	margin-bottom: 0;
}

.user-mode__select .multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	max-width: 100% !important;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 40px 0 20px;
	color: #6a6e83;
	background: initial;
	align-items: center;
	overflow: hidden;
}

.user-mode__select .multiselect__tags-wrap {
	align-items: center;
	height: 50px;
	overflow: hidden;
}

.user-mode__select .multiselect__tag {
	display: inline;
	padding: 0;
	color: #6a6e83;
	margin-right: 0;
	background: transparent !important;
	white-space: normal;
}

.user-mode__select .multiselect__tag-icon {
	display: none;
}

.user-mode__select .multiselect__tag:not(:first-child)::before {
	content: ',';
	margin: 0 5px 0 -5px;
}

.user-mode__select .multiselect__option {
	white-space: normal;
}

.user-mode__select .multiselect__option--highlight:after {
	display: none;
}

.user-mode__select .multiselect__select {
	height: 50px;
}

.user-mode__select .multiselect__single {
	line-height: 18px !important;
	font-size: 14px;
	background-color: transparent;
}
</style>
