<template>
	<div class="input-button overflow-hidden w-full flex items-center justify-between" :class="{ 'border-4 border-red-300': error, 'border border-textGray': !error }">
		<input
			class="input-button__input h-full px-5 py-2.5 w-full border-none focus:outline-none"
			type="text"
			:placeholder="placeholder"
			:value="value"
			@change="$emit('change')"
			:readonly="disabled"
			@input="updateInput"
		/>
		<button
			class="cursor-pointer h-full py-3 px-5 bg-dopBlue text-sm text-white font-bold tracking-wider focus:outline-none"
			:class="{ 'bg-textGray cursor-wait': disabled }"
			:disabled="disabled"
			@click="$emit('click')"
		>
			{{ buttonTitle }}
		</button>
	</div>
</template>

<script>
export default {
	name: 'TextInputButton',
	props: {
		placeholder: String,
		buttonTitle: String,
		disabled: {
			type: Boolean,
			default: false
		},
		value: [Number, String, Date],
		error: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		updateInput(e) {
			this.$emit('input', e.target.value);
		}
	}
};
</script>

<style scoped>
.input-button {
	border-radius: 10px;
	height: 50px;
}

.input-button__input {
	font-size: 14px;
	font-weight: 400;
	color: #6a6e83;
}

.input-button__input::placeholder {
	font-size: 14px;
	font-weight: 400;
	color: #6a6e83;
}
</style>
