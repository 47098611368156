<template>
	<div class="inline-block w-full p-0" v-if="isActive">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'Tab',
	props: {
		title: {
			type: String
		}
	},
	data() {
		return {
			isActive: true
		};
	}
};
</script>
