<template>
	<div class="flex flex-col relative">
		<!-- <div class="absolute right-0 -top-14">
			<Button text="Справка" icon="question-white" />
		</div> -->
		<div class="rounded-r-lg rounded-b-lg h-auto bg-white filter shadow-default">
			<div class="flex flex-col p-7">
				<div class="w-full flex flex-col gap-5 p-7 mb-10 rounded-lg border border-liteGray">
					<div class="w-full flex mb-5">
						<Button text="+ ИЛИ" class="mr-5" color="white" size="smoll" border="thick" @click="addInput('or')" :disabled="getDisabledBtnOr" />
						<Button text="+ И" color="white" size="smoll" border="thick" @click="addInput('and')" :disabled="getDisabledBtnAnd" />
					</div>
					<div class="w-full flex flex-col gap-5" v-for="(input, index) in getFiltersConstructorMZ.expertMode.expertInputs" :key="'A' + index">
						<div v-if="getDisabledBtnAnd && index > 0">
							<span class="text-sm text-textGray font-bold">ИЛИ</span>
						</div>
						<div class="w-full flex items-center gap-5">
							<div class="cursor-pointer">
								<Icons icon-name="delete" @click.native="deleteInput(index)" />
							</div>
							<div class="w-3/12 expert-mode__select">
								<multiselect
									tag-placeholder=""
									placeholder=""
									:show-labels="false"
									v-model="input.field"
									:options="fieldOptions"
									@select="resetOthersInput(input)"
									:searchable="false"
									:multiple="false"
									label="name"
									track-by="name"
								>
								</multiselect>
							</div>
							<div v-if="input.field" class="expert-mode__select" style="width: 130px">
								<multiselect
									tag-placeholder=""
									placeholder=""
									:show-labels="false"
									v-model="input.type"
									:options="typeOptions"
									@select="resetVal(input)"
									:searchable="false"
									:multiple="false"
									label="name"
									track-by="name"
								>
								</multiselect>
							</div>
							<div class="w-7/12 expert-mode__input" v-if="input.field?.type === 'string' && input.type">
								<TextInput placeholder="Введите текст..." v-model="input.value" />
							</div>
							<div class="w-7/12 expert-mode__input" v-if="input.field?.type === 'number' && input.type">
								<TextInput placeholder="Введите число..." v-model="input.value" type="number" />
							</div>
							<div :class="{ 'cursor-wait': input.load }" class="w-7/12 expert-mode__select relative" v-if="input.field?.type === 'select' && input.type">
								<multiselect
									:disabled="input.load"
									v-if="input.type?.fieldName === 'equal'"
									tag-placeholder=""
									placeholder=""
									:show-labels="false"
									v-model="input.value"
									:options="input.listGuide"
									:searchable="false"
									:multiple="true"
									label="name"
									track-by="name"
									:close-on-select="false"
									@select="onSelect($event, input.listGuide)"
									@remove="onRemove($event, input.listGuide)"
								>
									<template slot="option" slot-scope="props">
										<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
										<span>{{ props.option.name }}</span>
									</template>
								</multiselect>
								<multiselect
									:disabled="input.load"
									v-if="input.type?.fieldName === 'contains'"
									tag-placeholder=""
									placeholder=""
									:show-labels="false"
									v-model="input.value"
									:options="input.listGuide"
									:searchable="false"
									:multiple="false"
									label="name"
									track-by="name"
									@select="onSelect($event, input.listGuide)"
									@remove="onRemove($event, input.listGuide)"
								>
								</multiselect>

								<div v-if="input.load && input.type" class="absolute w-full h-full preloader rounded-lg">
									<loadingComponent class="absolute top-1/4 left-1/2" />
								</div>
							</div>
							<div :class="{ 'cursor-wait': input.load }" class="w-7/12 expert-mode__select relative" v-if="input.field?.type === 'selectEqualOnly' && input.type">
								<multiselect
									:disabled="input.load"
									v-if="input.type?.fieldName === 'equal'"
									tag-placeholder=""
									placeholder=""
									:show-labels="false"
									v-model="input.value"
									:options="input.listGuide"
									:searchable="false"
									:multiple="true"
									label="name"
									track-by="name"
									:close-on-select="false"
									@select="onSelect($event, input.listGuide)"
									@remove="onRemove($event, input.listGuide)"
								>
									<template slot="option" slot-scope="props">
										<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
										<span>{{ props.option.name }}</span>
									</template>
								</multiselect>

								<div v-if="input.load && input.type" class="absolute w-full h-full preloader rounded-lg">
									<loadingComponent class="absolute top-1/4 left-1/2" />
								</div>
							</div>
							<div :class="{ 'cursor-wait': input.load }" class="w-7/12 expert-mode__select relative" v-if="input.field?.type === 'selectEqualOnlySingle' && input.type">
								<multiselect
									:disabled="input.load"
									v-if="input.type?.fieldName === 'equal'"
									tag-placeholder=""
									placeholder=""
									:show-labels="false"
									v-model="input.value"
									:options="input.listGuide"
									label="name"
									track-by="name"
									@select="onSelect($event, input.listGuide)"
									@remove="onRemove($event, input.listGuide)"
								>
									<template slot="option" slot-scope="props">
										<span>{{ props.option.name }}</span>
									</template>
									<span slot="noResult">Упс! Рассмотрите возможность изменения поискового запроса.</span>
								</multiselect>

								<div v-if="input.load && input.type" class="absolute w-full h-full preloader rounded-lg">
									<loadingComponent class="absolute top-1/4 left-1/2" />
								</div>
							</div>
						</div>
					</div>
					<div class="w-full">
						<Button
							v-if="getDisabledBtnAnd && getFiltersConstructorMZ.expertMode.expertInputs.length > 0"
							text="+ ИЛИ"
							class="mr-5"
							color="white"
							size="smoll"
							border="thick"
							@click="addInput('or')"
						/>
						<Button v-if="getDisabledBtnOr && getFiltersConstructorMZ.expertMode.expertInputs.length > 0" text="+ И" color="white" size="smoll" border="thick" @click="addInput('and')" />
					</div>
				</div>
				<div class="w-full flex items-end gap-5">
					<div class="w-36">
						<Button text="Сбросить" size="full" @click="resetInput('reset', {}, null)" />
					</div>
					<div class="w-72">
						<Button text="Построить отчет" size="full" @click="getReport($http, getFiltersConstructorMZ.expertMode.expertInputs)" />
					</div>
					<div class="max-w-3xl w-full">
						<TextInputButton
							placeholder="Введите название отчета и нажмите сохранить, если хотите воспользоваться отчетом в будущем"
							buttonTitle="Сохранить"
							:disabled="buttonDisabled"
							v-model="nameSaved"
							@input="errorSaved = false"
							:error="errorSaved"
							@click="save($http, getFiltersConstructorMZ.expertMode.expertInputs, nameSaved)"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import Multiselect from 'vue-multiselect';
import Button from '@/components/Button.vue';
import TextInputButton from '@/components/TextInputButton.vue';
import Icons from '@/components/Icons.vue';
import getters from '../store/getters';
import mutations from '../store/mutations';
import loadingComponent from '@/components/Loading.vue';
import { setReports, getGuide, getMedicalOrgs } from '../store/actions';

export default {
	name: 'ExpertMode',
	components: {
		TextInput,
		Multiselect,
		Button,
		TextInputButton,
		Icons,
		loadingComponent
	},
	computed: {
		getFiltersConstructorMZ: getters.getFiltersConstructorMZ,
		getYears: getters.getYears,
		getRegions: getters.getRegions,
		getParams: getters.getParams,
		getDisabledBtnAnd: getters.getDisabledBtnAnd,
		getDisabledBtnOr: getters.getDisabledBtnOr
	},
	data() {
		return {
			tableName: '',
			nameSaved: '',
			errorSaved: false,
			buttonDisabled: false,
			fieldOptions: [
				{ name: 'Полное наименование МО', fieldName: 'Name', type: 'string' },
				{ name: 'Краткое наименование', fieldName: 'ShortName', type: 'string' },
				{ name: 'ИНН МО', fieldName: 'Inn', type: 'number' },
				{ name: 'Номенклатура МО', fieldName: 'NomenclatureId', type: 'selectEqualOnlySingle' },
				{ name: 'Включено в перечень определенного года', fieldName: 'YearStr', type: 'selectEqualOnly' },
				{ name: 'Условия оказания', fieldName: 'terms', type: 'selectEqualOnly' },
				{ name: 'Субъект', fieldName: 'RegionId', type: 'selectEqualOnlySingle' },
				{ name: 'Ведомственная принадлежность', fieldName: 'DepartmentBelongId', type: 'selectEqualOnlySingle' },
				{ name: 'Головное учреждение (основная МО)', fieldName: 'isMain', type: 'select' },
				{ name: 'Филиал', fieldName: 'isBranch', type: 'select' },
				{ name: 'Фактический адрес', fieldName: 'Address', type: 'string' },
				{ name: 'Почтовый адрес', fieldName: 'PostAddress', type: 'string' },
				{ name: 'Форма собственности', fieldName: 'OwnerShipId', type: 'selectEqualOnlySingle' },
				{ name: 'Примечание', fieldName: 'NoteId', type: 'selectEqualOnlySingle' },
				{ name: 'Адрес официального сайта', fieldName: 'WebSiteAddress', type: 'string' }
			],
			defaultTypeOptions: [
				{ name: 'Равно', fieldName: 'equal' },
				{ name: 'Содержит', fieldName: 'contains' }
			],
			optionsBoolean: [
				{ name: 'Да', fieldName: 'true' },
				{ name: 'Нет', fieldName: 'false' }
			],
			typeOptions: []
		};
	},
	methods: {
		changeDisabledBtn: mutations.changeDisabledBtn,
		updateExpertInputs: mutations.updateExpertInputs,
		loadTable: mutations.loadTable,
		changeDataTable: mutations.changeDataTable,
		defaultParams: mutations.defaultParams,
		calculateParams: mutations.calculateParams,
		changeIdForExcel: mutations.changeIdForExcel,
		changeFlagDownloadExcel: mutations.changeFlagDownloadExcel,
		onSelect(option, options) {
			let index = options.findIndex((item) => item.name === option.name);
			options[index].checked = true;
		},
		onRemove(option, options) {
			let index = options.findIndex((item) => item.name === option.name);
			options[index].checked = false;
		},
		addInput(button) {
			if (button === 'and') {
				this.changeDisabledBtn('disabledOr', true);
			} else {
				this.changeDisabledBtn('disabledAnd', true);
			}

			this.updateExpertInputs(
				'add',
				{
					field: null,
					type: null,
					value: null,
					buttonType: button,
					listGuide: [],
					load: false
				},
				null
			);
		},
		deleteInput(index) {
			this.updateExpertInputs('del', {}, index);

			if (this.getFiltersConstructorMZ.expertMode.expertInputs.length === 0) {
				this.changeDisabledBtn('disabledAnd', false);
				this.changeDisabledBtn('disabledOr', false);
			}
		},
		resetInput(name, obj, index) {
			this.updateExpertInputs(name, obj, index);

			this.changeDisabledBtn('disabledAnd', false);
			this.changeDisabledBtn('disabledOr', false);
		},
		getOptions(input) {
			let fName = input.field?.fieldName;

			if (fName === 'isMain' || fName === 'isBranch') {
				return this.optionsBoolean;
			}
			if (fName === 'RegionId' || fName === 'NomenclatureId' || fName === 'terms' || fName === 'DepartmentBelongId' || fName === 'OwnerShipId' || fName === 'NoteId' || fName === 'YearStr') {
				return [{ name: 'Равно', fieldName: 'equal' }];
			}
			return this.defaultTypeOptions;
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		resetOthersInput(input) {
			this.$nextTick(() => {
				if (input.type || input.value) {
					input.type = null;
					input.value = null;
					input.listGuide = [];
				}

				if (input.field.fieldName === 'NomenclatureId') this.tableName = 'nomenclature';
				else if (input.field.fieldName === 'terms') this.tableName = 'medCareCondition';
				else if (input.field.fieldName === 'DepartmentBelongId') this.tableName = 'departmentBelong';
				else if (input.field.fieldName === 'OwnerShipId') this.tableName = 'ownership';
				else if (input.field.fieldName === 'NoteId') this.tableName = 'note';
				else this.tableName = null;

				if (this.tableName) {
					input.load = true;

					getGuide(this.$http, this.tableName).then((res) => {
						res.data.data.forEach((el) => {
							input.listGuide.push({
								id: el.id || el.Id,
								name: el.lookupName,
								checked: false
							});
						});

						input.load = false;
					});
				}

				if (input.field.fieldName === 'YearStr') {
					input.listGuide = JSON.parse(JSON.stringify(this.getYears));
				}

				if (input.field.fieldName === 'RegionId') {
					input.listGuide = JSON.parse(JSON.stringify(this.getRegions));
				}

				if (input.field.fieldName === 'YearStr' || input.field.fieldName === 'RegionId') {
					for (let i = 0; i < input.listGuide.length; i += 1) {
						input.listGuide[i].checked = false;
					}
				}

				this.typeOptions = this.getOptions(input);

				if (this.typeOptions.length === 1) {
					input.type = this.typeOptions[0];
				}
			});
		},
		save(http, array, name) {
			if (array.length === 0) {
				this.showNotification('Заполните форму и выберите параметры для отчета!', 'error');
				return;
			}

			if (name.length === 0) {
				this.showNotification('Заполните название отчета!', 'error');
				this.errorSaved = true;
				return;
			}
			this.buttonDisabled = true;

			this.defaultParams();
			this.calculateParams(array);

			setReports(http, {
				name: name,
				data: this.getParams,
				filtersDefault: JSON.stringify(array),
				type: 2
			})
				.then((result) => {
					if (result.status === 200) {
						this.changeIdForExcel(result.data.id);
						this.changeFlagDownloadExcel(false);
						this.showNotification(`Сохранение отчета - ${this.nameSaved} прошло успешно!`, 'success');
						this.buttonDisabled = false;
						this.nameSaved = '';
					}
				})
				.catch((error) => {
					if (error) {
						this.showNotification('Ошибка сохранения, обратитесь к Администратору!', 'error');
						this.buttonDisabled = false;
					}
				});
		},
		resetVal(input) {
			if (input.value) {
				input.value = null;
			}
		},
		getReport(http, array) {
			if (array.length === 0) {
				this.showNotification('Заполните форму и выберите параметры для отчета!', 'error');
				return;
			}
			this.defaultParams();
			this.loadTable(true);
			this.calculateParams(array);

			getMedicalOrgs(http, this.getParams).then((res) => {
				this.changeDataTable(res.data);
				this.changeFlagDownloadExcel(true);
				this.loadTable(false);
			});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.expert-mode__input > .input-box {
	margin-bottom: 0;
}

.expert-mode__input .input-box label {
	margin-bottom: 0;
}

.expert-mode__input > .input-box input[type='number'] {
	-moz-appearance: textfield;
}

.expert-mode__select > .multiselect {
	margin-bottom: 0;
}

.expert-mode__select .multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	max-width: 100% !important;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
	background: initial;
	display: flex;
	align-items: center;
}

.expert-mode__select .multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
	overflow: hidden;
}

.expert-mode__select .multiselect__tag {
	padding: 0;
	color: #6a6e83;
	margin-right: 0;
	background: transparent !important;
	overflow: visible;
}

.expert-mode__select .multiselect__tag-icon {
	display: none;
}

.expert-mode__select .multiselect__tag:not(:first-child)::before {
	content: ',';
	margin-right: 5px;
}

.expert-mode__select .multiselect__option--highlight:after {
	display: none;
}

.expert-mode__select .multiselect__select {
	height: 50px;
}

.expert-mode__select .multiselect__single {
	line-height: 18px !important;
	font-size: 14px;
	padding: 0;
	background-color: transparent;
}
</style>
