<template>
	<div class="t-overflow rounded-lg overflow-x-auto h-auto bg-white filter shadow-default">
		<table class="table-fixed w-full">
			<thead>
				<tr>
					<th
						v-for="(item, ind) in fields"
						:key="'J' + ind"
						class="px-6 py-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						:style="item.width !== null ? 'width: ' + item.width : ''"
					>
						<span class="flex relative" v-if="notFiltered">
							<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
								{{ item.Name }}
							</span>
							<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:position="ind === 0 ? 'left' : 'right'"
								:filterOpen="filterOpen"
								:id-filter="ind"
								:param="params"
								@query="getData"
								:fields="fields"
								:item="item"
							/>
						</span>
						<span v-else>
							{{ item.Name }}
						</span>
					</th>
				</tr>
			</thead>
			<tbody v-for="(item, indx) in data" :key="'A' + indx">
				<tr>
					<td v-for="(field, ind) in fields" :key="'L' + ind" class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-left text-gray-500">
						<span>
							{{ item[field.fieldName] }}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
		<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
	</div>
</template>

<script>
import FilterComponent from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';

export default {
	name: 'Table',
	props: {
		fields: Array,
		data: Array,
		loadingTable: Boolean,
		notFiltered: Boolean
	},
	components: {
		FilterComponent,
		Icons
	},
	data() {
		return {
			filterOpen: null,
			filterBy: null,
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 100,
				currentPage: 0
			}
		};
	},
	methods: {
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params) {
			console.log(params);
			this.loadingTable = true;
			// api call
		}
	}
};
</script>

<style scoped></style>
