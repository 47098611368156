import Vue from 'vue';

export default Vue.observable({
	years: [],
	regions: [],
	filtersConstructorMZ: {
		flagDownloadExcel: true,
		idForExcel: null,
		expertMode: {
			expertInputs: [],
			loadingTable: false,
			dataTable: {
				data: [],
				totalRows: 0,
				totalCount: 0,
				page: 0
			},
			disabledAnd: false,
			disabledOr: false
		},
		userMode: {
			filters: {
				Name: '',
				ShortName: '',
				Inn: '',
				NomenclatureId: [],
				YearStr: [],
				RegionId: [],
				DepartmentBelongId: [],
				OwnerShipId: [],
				NoteId: [],
				WebSiteAddress: '',
				Address: '',
				PostAddress: '',
				Ambulator: null,
				HealthResort: null,
				Psychiatric: null,
				Stacionar: null,
				isMain: null,
				isBranch: null
			},
			nomenclature: [],
			departmentBelong: [],
			ownership: [],
			note: [],
			ambulatory: [
				{ title: 'Да', id: 'input_1', checked: null },
				{ title: 'Нет', id: 'input_2', checked: null }
			],
			sanatory: [
				{ title: 'Да', id: 'input_3', checked: null },
				{ title: 'Нет', id: 'input_4', checked: null }
			],
			psychiatric: [
				{ title: 'Да', id: 'input_5', checked: null },
				{ title: 'Нет', id: 'input_6', checked: null }
			],
			stationary: [
				{ title: 'Да', id: 'input_7', checked: null },
				{ title: 'Нет', id: 'input_8', checked: null }
			],
			headOffice: [
				{ title: 'Да', id: 'input_9', checked: null },
				{ title: 'Нет', id: 'input_10', checked: null }
			],
			branchOffice: [
				{ title: 'Да', id: 'input_11', checked: null },
				{ title: 'Нет', id: 'input_12', checked: null }
			]
		}
	},
	params: {
		filters: [],
		sortOption: {
			fieldName: '',
			sortType: null
		},
		pageSize: 100,
		currentPage: 0,
		useANDoperator: null,
		columns: []
	},
	setMapFilType: {
		equal: 1,
		contains: 2,
		true: 1,
		false: 1
	},
	setMapFilValType: {
		string: 1,
		num: 2,
		select: 2,
		selectEqualOnly: 1,
		selectEqualOnlySingle: 1
	},
	setMapConditionsOfRendering: {
		'Амбулаторные условия': 'Ambulator',
		'Стационарные условия': 'Stacionar',
		Санатории: 'HealthResort',
		'Психиатрические больницы': 'Psychiatric'
	},
	setMapConditionsOfRenderingReverse: {
		Ambulator: 'Амбулаторные условия',
		Stacionar: 'Стационарные условия',
		HealthResort: 'Санатории',
		Psychiatric: 'Психиатрические больницы'
	}
});
